import { createModalDialog } from '@cliotech-web/modal-dialog';
import { logMessage } from '../../js/common/logger';
import { requestAnimationFrame } from '../../js/common/raf';
import { localSaveRaw, localLoadRaw, savecookie } from '../../storageService';
import { preventBodyScroll } from 'CommonUtils/preventBodyScroll.js';
import './modal.css';


let text = {};
let text_body = '';

let lang = languageAttributes.languageCode;

if (languageAttributes.broker === '3') {

  lang = ['es', 'hu', 'cz'].indexOf(lang) >= 0 ? lang + '_eu' : lang;

  text = require('../../content/' + lang + '/cookie-consent.json');

  text_body = `<div class="term term1">
                  <p class="cc-title">${text.cc_1_title}</p>
                  <div class="c1-btn">${text.cc_1_text2}</div>
                  <p class="cc-text">${text.cc_1_text}</p>
                </div>
                <div class="term DisablePerfCookies">
                  <p class="cc-title">${text.cc_2_title}</p>
                  <div id="ct_DisablePerfCookies" data-ct="DisablePerfCookies" class="c2-btn term-btn"><span class="cc-toggle"></span></div>
                  <p class="cc-text">${text.cc_2_text}</p>
                </div>
                <div class="term DisableTargetingCookies mb-0">
                  <p class="cc-title">${text.cc_3_title}</p>
                  <div id="ct_DisableTargetingCookies" data-ct="DisableTargetingCookies" class="c3-btn term-btn"><span class="cc-toggle"></span></div>
                  <p class="cc-text">${text.cc_3_text}</p>
                </div>
                <p class="cc-last-p">${text.cc_4_text}</p>`;

}

const cookieSettingsCookieName = 'cookie_settings';





let cookies_settings = { DisablePerfCookies: 0, DisableTargetingCookies: 0 };

function addClassActive(term) {
  requestAnimationFrame(() => {
    document.getElementsByClassName(term)[0].classList.toggle("active");
  })

}



function init_settings() {
  try {

    Object.keys(cookies_settings).forEach(function (key) {
      let val = Number(localLoadRaw(key) ?? 0);
      cookies_settings[key] = isNaN(val) ? 0 : val;
      // cookies_settings[key] = JSON.parse(cookies_settings[key])

      if (cookies_settings[key] == 1) {
        deleteCookies(key);
      }

    })


  } catch (error) {
    console.error(error);
  }

  Object.keys(cookies_settings).forEach(function (key) {
    if (!cookies_settings[key]) {
      addClassActive(key);
    }
  })


  requestAnimationFrame(() => {
    const ct_DisablePerfCookies = document.getElementById('ct_DisablePerfCookies'), ct_DisableTargetingCookies = document.getElementById('ct_DisableTargetingCookies');

    ct_DisablePerfCookies.addEventListener('click', () => { saveTerm(ct_DisablePerfCookies.dataset.ct) });
    ct_DisableTargetingCookies.addEventListener('click', () => { saveTerm(ct_DisableTargetingCookies.dataset.ct) });


    let title_elements = document.querySelectorAll(".cc-title");

    title_elements.forEach(title => {
      title.addEventListener('click', ev => { toggleTab(title) });
    });

  })

}

const deleteCookies = function (key) {

  let pairs = document.cookie.split(";");
  //let lp_domain = window.location.host.toLowerCase().replace('local','');
  let lp_domain = window.location.host.toLowerCase().replace('www', '');
  let lp_domain1 = window.location.host.toLowerCase();

  pairs.forEach(function (pair) {

    pair = pair.replaceAll(' ', '').split('=')[0];

    if (key === 'DisableTargetingCookies') {
      if (pair.startsWith('__utm') || pair == '_fbp') {
        document.cookie = pair + '=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=' + lp_domain;
        document.cookie = pair + '=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=' + lp_domain1;
      }
    } else if (key == 'DisablePerfCookies') {
      if (pair.startsWith('__test') || pair.startsWith('_vwo_') || pair.startsWith('_hj') || pair == 'B' || pair == '_ga' || pair == '_gat') {
        document.cookie = pair + '=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=' + lp_domain;
        document.cookie = pair + '=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=' + lp_domain1;
      }
    }


  })
}

let all_accepted = true;

function updateCookieExpiration(days) {
  let expires = "";
  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); 
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = "fx_cn=1" + expires + "";
}



function save_settings() {

  Object.keys(cookies_settings).forEach(function (key) {
    localSaveRaw(key, Number(cookies_settings[key]));
    if (Number(cookies_settings[key]) == 1) {
      deleteCookies(key);
      all_accepted = false;
    }
  })


  closeLightBox("pp_agreement");
  closeLightBox('cookie-notif-eu');
 


  updateCookieExpiration(all_accepted ? '365' : '90')

  function setConsentPermission(flagValue) {
    let denied = 'denied', granted = 'granted';
    if ((flagValue === undefined || flagValue === null) && languageAttributes.broker === '3') return denied;
    if (flagValue === undefined || flagValue === null) return granted;
    return +flagValue === 0 ? granted : denied;

  }

 
    let settings = {};
    settings.functionality_storage = setConsentPermission(cookies_settings.DisablePerfCookies);
    settings.personalization_storage = setConsentPermission(cookies_settings.DisablePerfCookies);
  
    settings.ad_user_data = setConsentPermission(cookies_settings.DisableTargetingCookies);
    settings.ad_personalization = setConsentPermission(cookies_settings.DisableTargetingCookies);
    settings.ad_storage = setConsentPermission(cookies_settings.DisableTargetingCookies);
    settings.analytics_storage = setConsentPermission(cookies_settings.DisableTargetingCookies);
    try {
    localSaveRaw('googleConsentState', JSON.stringify(settings));
    localSaveRaw('googleConsentStateTime', new Date().getTime());


    window.gtag('consent', 'update', settings)


  } catch (x) { }
  window.dataLayer.push({ 'event': "cookies_settings_click", 'cookieSettings': cookies_settings });
  logMessage({ eid: "save_cookies_settings", settings: cookies_settings })

}

function allow_all() {

  Object.keys(cookies_settings).forEach(function (key) {
    if (cookies_settings[key] == 1) {
      saveTerm(key);
    }
  });


  logMessage({ eid: 'accept_all_cookies' });

}

function saveTerm(term) {
  cookies_settings[term] = cookies_settings[term] == 1 ? 0 : 1;
  addClassActive(term);

}

function toggleTab(elem) {
  elem.parentElement.classList.toggle("open");
}

window.acceptAllCookies = function () {
  allow_all();
  save_settings();
}

window.show_cookie_consent = function () {
  preventBodyScroll()
  requestAnimationFrame(() => {
    closeLightBox('cookie-notif-eu');
    document.getElementById('cookie-consent').classList.remove('hide')
  });
  logMessage({ eid: "show_cookies_consent_tool" })
}

let options = {
  id: 'cookie-consent',
  name: 'cookie-consent',
  title: text.cc_title,
  closeButton: () => { preventBodyScroll(); return '' },
  message: text_body,
  commands: [{
    text: text.btn2_text,
    name: 'cc-btn-all',
    close: false,
    action: () => { allow_all(); return true }
  }, {
    text: text.btn1_text,
    name: 'cc-btn-save',
    close: true,
    action: () => { save_settings(); return true }
  }],
  callBack: () => { init_settings() }
}

let modal;

if (languageAttributes.broker === '3') {
  requestAnimationFrame(() => {
    modal = createModalDialog(options);
  });
}
