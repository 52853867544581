import { logMessage } from './logger';


function openLink(target, link){

    !target ? window.location.href = link : window.open(link,target);
}

async function  sendTrackingEvent(e){

    let link = e.target.href || '';
    let target = e.target.target;


    e.preventDefault(); // Prevent the default link behavior
        let elem =  e.target,
            elem_class =  elem.className,
            device = window.outerWidth > 768 ? 'desk' : 'mobile',
            msg = '',
            sendLog = true;
            
        if(elem_class.includes('icon-bar')){
            msg = 'menu_open_' + device;

        }
        else if(elem_class.includes('tracking-link')){
            msg = elem.dataset.name + '_' + device;

        }else{
            sendLog = false;
        }

        if(sendLog){
            
            logMessage({eid: msg });
          
            if(link){
                target === '_blank' ? 
                 openLink(target, link) : 
                setTimeout(() => { openLink(target, link) }, "250");

            }
            
        }

    }


    export function addClickEventTracking(){

        let tracking_list = document.getElementsByClassName("tracking-link");

        for (var i = 0; i < tracking_list.length; i++) {

            let item = tracking_list[i];
            
            if (!item.className.includes('tracking-added')) {
                item.classList.add('tracking-added')
                item.addEventListener('click',  e => {sendTrackingEvent(e)
                });
            }
        }
    }

