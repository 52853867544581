const brand = languageAttributes.brand;
export default function () {
    
    window.fxtracking = [];
    window.emerpq = window.emerpq || [];
    window.emerpinit = false;
    window.conversiondata = window.conversiondata || [];
    window.postlead = window.postlead || [];
    window.isWebsite = true;

    if (window.pageTracking) {

        let trigger = window.getparam('trigger')  || pageTracking.trigger;
        if (trigger ) {
            conversiondata.push('trigger', trigger);
            postlead.push('trigger', trigger);
        }
        if (pageTracking.dsps) {
            for (let i = 0; i < pageTracking.dsps.length; i++) {
                fxtracking.push([`dsp${pageTracking.dsps[i][0]}`, pageTracking.dsps[i][1]]);
            }
        }
    }

    function handlepublishers() {

        function createID() {
            let a = [], i = 10;
            while (i--) {
                a.push('abcdef1234567890'.substr(Math.floor(Math.random() * 16), 1));
            }

            return a.join("");
        }

        try {
            window._pxid = window._pxid || (readcookie('_px') || [])[1] || createID();
            window.fxtracking.push(['extid4', _pxid]);
        } catch (x) {
        }
    }

    function handleInternal() {
        try {
            let tests = JSON.parse(readcookie("itests"));
            if (tests) {
                for (let i in tests) {
                    if (tests.hasOwnProperty(i)) {
                        window.fxtracking.push(['dsp2', i]);
                        window.fxtracking.push(['dsp3', tests[i]]);
                    }
                }
            }
        } catch (x) {
        }
    }

    

    function handleVWO() {
        window._vis_opt_queue = window._vis_opt_queue || [];
        let vis_counter = 0;
        window._vis_opt_queue.push(function () {
            try {
                var tests = [];
                var variations = [];

                if (!vis_counter) {
                    let vis_data = {}, vis_combination, vis_id = null, vis_l = 0;
                    for (; vis_l < window._vwo_exp_ids.length; vis_l++) {
                        vis_id = window._vwo_exp_ids[vis_l];
                        if (_vwo_exp[vis_id].ready) {
                            vis_combination = window._vis_opt_readCookie('_vis_opt_exp_' + vis_id + '_combi');

                            if (typeof _vwo_exp[vis_id].combination_chosen != "undefined")
                                vis_combination = window._vwo_exp[vis_id].combination_chosen;

                            if (typeof (_vwo_exp[vis_id].comb_n[vis_combination]) != "undefined") {
                                tests.push('VWO-Test-ID-' + vis_id);
                                variations.push(_vwo_exp[vis_id].comb_n[vis_combination]);
                                vis_counter++;
                            }
                        }
                    }
                    if (vis_counter) {
                        window.fxtracking.push(['dsp2', tests.join(",")]);
                        window.fxtracking.push(['dsp3', variations.join(",")]);
                        for (var i = 0; i < tests.length; i++) dataLayer.push({
                            "event": "part-of-test",
                            "testid": tests[i],
                            "testvariation": variations[i]
                        });
                    }
                }
            } catch (err) {
            }
        });
    }

    (function (_gaq, emerpq) {
        window.conversiondata = window.conversiondata || [];
        window.postlead = window.postlead || [];
        window._gaq = _gaq || [];
        window.getparam = window['getparam'] ||
            function (name) {
                var re = new RegExp("[&\?]" + name + "=([^&]*)", "i");
                var m = document.location.search.match(re);
                return (m != null && m.length > 1) ? m[1] : "";
            };
        window._countryName = languageAttributes.defaultCountryName;
        window._broker = languageAttributes.broker;
        window._brand = brand || "iforex";
        window.fxgeoip = {
            code: languageAttributes.fxgeoip_code,
            country: languageAttributes.defaultCountryName,
            name: languageAttributes.defaultCountryName
        };
        window.tm_language = languageAttributes.name;


        window.shouldsendhphit = false;
        window._gaq = [];
        window.dataLayer.push({
            "section": getparam("SID") ? "LP" : "Site",
            "serial": getparam("SID") || getparam("content") || document.location.href,
            "content": getparam("content") || document.location.href
        });
        window.fxo = {};

        if (languageAttributes.broker == 3 && window._brand != "iforex"){
            window.fxo = {gdpr2:!0};
        }

        window.phonePrefixPaddingLeft = '65px';

        window._fxreferrer = '';
        try {
            window._fxreferrer = encodeURIComponent(document.referrer);
        } catch (x) {
            window._fxreferrer = 'not-provided';
        }

        window.Drupal = {
            settings: {
                current_lang: languageAttributes.languageCode
            }
        };


        handlepublishers();
        handleInternal();
        handleVWO();

        const script = document.createElement('script');
        document.body.appendChild(script);

        script.async = true;
        script.defer = true;
        script.src = `https://content.webapi-services.net/webapi/tracking/ec.js?brand=${brand}`;
    })(window._gaq, window.emerpq);
}