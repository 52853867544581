function sessionSave(key, value) {
    try {
        let item = JSON.stringify(value);
        sessionStorage.setItem(key, item);
    } catch (err) {
        console.error("sessionStorage denied", err);
    }
}

function sessionLoad(key) {
    try {
        let item = sessionStorage.getItem(key);
        let value = JSON.parse(item);
        return value;
    } catch (err) {
        console.error("sessionStorage denied", err);
    }
}

function localSave(key, value) {
    try {
        let item = JSON.stringify(value);
        localStorage.setItem(key, item);
    } catch (err) {
        console.error("localStorage denied", err);
    }
}

function localLoad(key) {
    try {
        let item = localStorage.getItem(key);
        let value = JSON.parse(item);
        return value;
    } catch (err) {
        console.error("localStorage denied", err);
    }
}


export function localLoadRaw(key) {
    try {
        return localStorage.getItem(key);
    } catch (err) {
        console.error("localStorage denied", err);
    }
    return null;
}


export function localSaveRaw(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch (err) {
        console.error("localStorage denied", err);
    }
}

export function savecookie (a, b, c) {
    try {
        let d = new Date();
        d.setTime(d.getTime() + 864E5 * ("undefined" != typeof c ? c : 3));
        c = "; expires\x3d" + d.toGMTString();
        document.cookie = a + "\x3d" + b + c + "; path\x3d/;domain\x3d" + document.domain.replace(/^[^\.]+/, '')
    } catch(x){}
};

export function readcookie (a) {
    a = new RegExp("(?:^| )" + a + "\x3d([^;]*)", "i");
    try {
        let m = document.cookie.match(a);
        if (m && m.length === 2) {
            return m[1];
        }
    } catch(x){}
    return null;
};


export const storageService = {
    sessionSave,
    sessionLoad,
    localSave,
    localLoad,
    savecookie,
    readcookie
};



