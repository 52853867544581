
import { localSaveRaw, localLoadRaw, readcookie  } from '../../storageService';
import { logMessage } from '../../js/common/logger';
import { preventBodyScroll } from 'CommonUtils/preventBodyScroll.js';

let geoip_cookie = readcookie('geoip');


function accept(){
  //save in local storage
  preventBodyScroll();
 
  try {
    logMessage({ eid: 'portugueseBr1Accept' });
    localSaveRaw("ptBr1accept", "1");
      //close the pop up
    ptPopUp.style.display = 'none';
  } catch (error) {
    console.error(error);
  }
}


let ptBr1acceptLoaded = localLoadRaw("ptBr1accept") ? true : false;

function LoadPopUp(){
  //check if saved in local storage
     //yes - do nothing
      //no  - disaply pop up

    ptPopUp.style.display = 'block';
    preventBodyScroll(); 
}


const ptPopUp = document.getElementById('ptPopUP');

geoip_cookie == 'BR' && ptPopUp && !ptBr1acceptLoaded && LoadPopUp();

var acceptBtn = document.getElementById('closePt');

acceptBtn && acceptBtn.addEventListener('click', function(e){
  accept();
});




