

export async function logMessage(message) {
    const payload = {
        "log-message": Object.assign(message, {uid: window.promocode, s: window.serial})
    };

    try {

          const response = await fetch(WEBLOGGER_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const res = await response.json();
        
            return res;

     
    } catch(x) {
        console.error(x);
    }
}