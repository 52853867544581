import { createModalDialog } from '@cliotech-web/modal-dialog';
import { localSaveRaw, localLoadRaw, readcookie  } from '../../storageService';
import {requestAnimationFrame} from 'CommonUtils/raf.js';
import './modal.css';
import { preventBodyScroll } from 'CommonUtils/preventBodyScroll.js';
import { logMessage } from '../../js/common/logger';

let lang = languageAttributes.spokenLanguageCode;
let lang_code = languageAttributes.languageCode;

if(lang == "hi"){
  lang_code = lang_code == 'hi' ? 'hi_hi' : lang_code;
}

let text = languageAttributes.broker === '1' ? require('../../content/' + lang_code + '/broker-choice-modal.json') : {};

let geoip_cookie = readcookie('geoip');


function redirect_agree() {
  window.location.href = EEA_COUNTRIES[geoip_cookie] != '' ? EEA_COUNTRIES[geoip_cookie] : "https://www.iforex.eu";
  try {
    logMessage({eid:'brokerChoice-agree'})
  } catch (error) {
    console.error(error);
  }
}


function redirect_decline() {
  preventBodyScroll();
  try {
    localSaveRaw(`brokerChoice_${lang}`, "1");
    logMessage({eid:'brokerChoice-decline'});
  } catch (error) {
    console.error(error);
  }
}

function placeContent() {
  document.getElementsByClassName('md-btn-wrap')[0].setAttribute('data-content', text.text2);
}


let options = {
  id: 'broker-choice-modal',
  name: 'string',
  title: text.title,
  closeButton: () => { return '' },
  message: text.text,
  commands: [{
    text: text.btn1,
    name: 'btn1',
    close: true,
    action: () => { redirect_agree(); return true }
  },
  {
    text: text.btn2,
    name: 'btn2',
    close: true,
    action: () => { redirect_decline(); return true }
  }],
  callBack: () => { placeContent() }
}

let brokerChoiceModalLoaded = false;
let eea_country = Object.keys(EEA_COUNTRIES).includes(geoip_cookie);


try {
  brokerChoiceModalLoaded = localLoadRaw(`brokerChoice_${lang}`) ? true : false;
} catch (error) {
  console.error(error);
}

window.addEventListener("load", () => {

  if (languageAttributes.broker === '1' && !brokerChoiceModalLoaded && eea_country) {

    let modal = createModalDialog(options);

    requestAnimationFrame( () => {preventBodyScroll(); modal.show()} );

  
  }
});


